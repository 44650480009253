"use client";

import { useForm } from "react-hook-form";
import React, { useState } from "react";
import axios from "axios";
import toast from "react-hot-toast";
import { prodServerUrl } from "../../app/utils/globals";

const ContactForm = () => {
  const [disable, setDisable] = useState(false);

  const {
    handleSubmit,
    register,
    formState: { errors },
    setValue,
  } = useForm();

  const submitHandler = async ({
    fullName,
    email,
    phone,
    subject,
    message,
  }) => {
    try {
      if (email) {
        setDisable(true);
      }

      const data = await axios.post(`${prodServerUrl}/lead-forms`, {
        fullName: fullName,
        email: email,
        phone: phone,
        subject: subject,
        message: message,
      });

      if (data.status === 201) {
        toast.success(
          "We have received your query. Your will be contacted soon."
        );
        setValue("fullName", "");
        setValue("email", "");
        setValue("phone", "");
        setValue("subject", "");
        setValue("message", "");

        setDisable(false);
      }
    } catch (e) {
      console.log(e);
      toast.error(e.message);
      setDisable(false);
    }
  };

  return (
    <form
      className="mx-auto max-w-screen-md"
      onSubmit={handleSubmit(submitHandler)}
    >
      <div className="mb-4 font-Poppins">
        <label
          htmlFor="fullName"
          className="text-sm font-Josefin font-medium text-white"
        >
          Full Name
        </label>
        <input
          className="w-full rounded h-[30px] p-2 text-sm text-primary"
          id="fullName"
          name="fullName"
          type="text"
          placeholder="First Name"
          {...register("fullName", {
            required: "Please enter your Name",
          })}
        />
        {errors.fullName && (
          <div className="text-red-500">{errors.fullName.message}</div>
        )}
      </div>
      <div className="mb-4 font-Poppins">
        <label
          htmlFor="email"
          className="text-sm font-Josefin font-medium text-white"
        >
          Email
        </label>
        <input
          className="w-full rounded h-[30px] p-2 text-sm text-primary"
          id="email"
          name="email"
          placeholder="Email"
          type="email"
          {...register("email", {
            required: "Please enter your Email",
          })}
        />
        {errors.email && (
          <div className="text-red-500">{errors.email.message}</div>
        )}
      </div>
      <div className="mb-4 font-Poppins">
        <label
          htmlFor="phone"
          className="text-sm font-Josefin font-medium text-white"
        >
          Phone
        </label>
        <input
          className="w-full rounded h-[30px] p-2 text-sm text-primary"
          id="phone"
          name="phone"
          placeholder="Phone"
          type="text"
          {...register("phone", {
            required: "Please enter your Phone Number",
          })}
        />
        {errors.phone && (
          <div className="text-red-500">{errors.phone.message}</div>
        )}
      </div>
      <div className="mb-4 font-Poppins">
        <label
          htmlFor="subject"
          className="text-sm font-Josefin font-medium text-white"
        >
          Subject
        </label>
        <input
          className="w-full rounded h-[30px] p-2 text-sm text-primary"
          id="subject"
          name="subject"
          placeholder="Subject"
          type="text"
          {...register("subject", {
            required: "Please enter Message Subject",
          })}
        />
        {errors.subject && (
          <div className="text-red-500">{errors.subject.message}</div>
        )}
      </div>

      <div className="mb-4 font-Poppins">
        <label
          htmlFor="message"
          className="text-sm font-Josefin font-medium text-white"
        >
          Message
        </label>
        <textarea
          className="w-full rounded h-[80px] p-2 text-sm text-primary"
          id="message"
          name="message"
          placeholder="Your message"
          type="text"
          {...register("message", {
            required: "Please enter your Message",
          })}
        />
        {errors.message && (
          <div className="text-red-500">{errors.message.message}</div>
        )}
      </div>

      <div className="text-center md:text-end font-Poppins">
        <button
          className={`${
            disable ? "bg-colorTextSecondary" : "bg-yellow"
          } text-sm font-Josefin font-medium px-4 py-2 rounded mt-4`}
          type="submit"
          disabled={disable ? true : false}
        >
          Submit
        </button>
      </div>
    </form>
  );
};

export default ContactForm;
