import React from "react";
import AllFaculties from "./AllFaculties";
import SwiperSlider from "../test-slider/SwiperSlider";

const FacultySection = () => {
  return (
    <>
      <div className="px-6 py-8 md:py-14 md:px-16 lg:px-56">
      <div className="mb-4">
        <h4 className="text-2xl font-semibold text-colorHeading">
          EXPERIENCED PROFESSIONALS
        </h4>
        <div className="underline"></div>
        <h3 className="my-4 text-lg md:text-base text-[#1e86af] font-semibold font-Poppins leading-10">
          Meet the faculties of Vidyastu
        </h3>
      </div>
      {/* <div className="">
        <AllFaculties />
      </div> */}
    </div>
    <SwiperSlider />
    </>
  );
};
export default FacultySection;
