import { InfiniteMovingCards } from "@/components/ui/InfiniteMovingCard";
import Companies from "./Companies";
import { testimonials } from "@/data/data";

const CollaboratedCompanySection = () => {
  return (
    <div className="container px-6 py-8 m-auto md:py-14 md:px-16 lg:px-56">
      <div className="mb-4 lg:-ml-36">
        <h3 className="text-2xl font-semibold text-colorHeading uppercase">
          Our Alumni Works At
        </h3>
        <div className="underline"></div>
        <h3 className="my-4 text-lg md:text-base text-[#1e86af] font-semibold font-Poppins leading-10">
          Grow your skills and learn with us from everywhere
        </h3>
      </div>
      <InfiniteMovingCards 
        items={testimonials}
        direction="left"
        speed="slow"
      />
    </div>
  );
};

export default CollaboratedCompanySection;
