const freecourseshome = [
  {
    id: 1,
    image:
      "https://res.cloudinary.com/dgj931yed/image/upload/v1723127389/Vidyastu%20App%20Images/mxsdbrupd8iws1hxsxhm.png",
    coursetitle: "Computer Science And Engineering",
    faculty: "Nirmalya Sengupta, Ushashi Chowdhury, Soumyadeep Paul",
    playlist:
      "https://youtube.com/playlist?list=PLCA5omKpatF8-YLcQ6ZtKbYrVx2WesPnY&si=F-Fbw8i1r6ivx9Me",
  },
  {
    id: 2,
    image:
      "https://res.cloudinary.com/dgj931yed/image/upload/v1717917851/vwb0ljctx52rrn7dh5xt.png",
    coursetitle: "Wordpress Development Using Elementor",
    faculty: "Sharmistha Chatterjee",
    playlist:
      "https://www.youtube.com/playlist?list=PLCA5omKpatF8SEMeT1GXFZ3LBCEib8k5M",
  },
  {
    id: 3,
    image:
      "https://res.cloudinary.com/dgj931yed/image/upload/v1717916973/l6zsi8giews8krqbkzno.png",
    coursetitle: "C Programming Language",
    faculty: "Ushashi Chowdhury",
    playlist:
      "https://www.youtube.com/playlist?list=PLCA5omKpatF8jA4dVEeqsl4sawrrLxi3n",
  },
  {
    id: 4,
    image:
      "https://res.cloudinary.com/dgj931yed/image/upload/v1717918213/sao99t0xe3fqpcgmcvyc.png",
    coursetitle: "Python Programming",
    faculty: "Nirmalya Sengupta",
    playlist:
      "https://www.youtube.com/playlist?list=PLCA5omKpatF89tx5klBP3MQYbstpkTK5D",
  },
  {
    id: 5,
    image:
      "https://res.cloudinary.com/dgj931yed/image/upload/v1717918826/qsjphv4jpqprsaz3yxei.png",
    coursetitle: "Free AI, chatGPT And Prompt Engineering",
    faculty: "Ushashi Chowdhury",
    playlist:
      "https://www.youtube.com/playlist?list=PLCA5omKpatF8NR5iqKlbGd6X6mMdBLs-R",
  },
];
export default freecourseshome;
