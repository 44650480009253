// const prodServerUrl = "https://vidyastu-modified-backend.onrender.com/api/v1";

const prodServerUrl = "https://vidyastu-server.onrender.com/api/v1";
const devServerUrl = "http://localhost:8000/api/v1";
const mainServerUrl = "https://www.vidyastu.in/";


export { prodServerUrl, devServerUrl, mainServerUrl };


// const prodServerUrl = "http://localhost:8000/api/v1";
// export { prodServerUrl };
