import Link from "next/link";

const CallToActionButton = ({ btnText, link }) => {
  return (
    <Link
      href={link}
      className="bg-colorAqua hover:bg-colorOrange ease-in duration-300 font-semibold shadow-sm px-4 py-2 rounded-md text-white text-xs"
    >
      {btnText}
    </Link>
  );
};

export default CallToActionButton;
