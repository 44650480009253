import Link from 'next/link';
import React from 'react';
import { FaWhatsapp } from "react-icons/fa6";
import './whatsapp.css';


const WhatsAppButton = () => {
  return (
    <div>
      <Link 
        href="https://api.whatsapp.com/send/?phone=919051977167&text=%22I%20am%20from%20your%20website.%22&type=phone_number&app_absent=0"
      >
        <FaWhatsapp 
          className='w-10 h-10 md:w-12 md:h-12 text-black fixed bottom-14 right-9 z-[100] bg-green-500 rounded-full p-3 whatsapp'
        />
      </Link>
    </div>
  )
}

export default WhatsAppButton