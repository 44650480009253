"use client";

import React, { useState } from "react";
import axios from "axios";
import toast from "react-hot-toast";
import { prodServerUrl } from "../../app/utils/globals";

const ContactForm = () => {
  const [disable, setDisable] = useState(false);
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  
  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!name && !email && !phone) {
      toast.error('All Fields Are Necessary');
      return;
    }

    try {
      const res = await axios.post(`${prodServerUrl}/lead-forms`, {
        name, email, phone
      })

      if (res.status === 201) {
        alert('We have received your query. You will be contacted soon');
        const form = e.target;
        form.reset();
      }
    } catch (error) {
      console.log('An Error Occured');
      
    }
  }

  return (
    <form
      className="mx-auto max-w-screen-md border border-green-400 p-4 md:p-6 rounded-3xl"
      onSubmit={handleSubmit}
    >
      <div className="mb-7 font-Poppins">
        <input
          className="w-full rounded-3xl h-[40px] p-4 text-sm bg-green-200 text-colorPrimary focus:outline-none"
          onChange={e => setName(e.target.value)}
          type="text"
          placeholder="Your Name"
          required
        />
        
      </div>
      <div className="mb-7 font-Poppins">
        <input
          className="w-full rounded-3xl h-[40px] p-4 text-sm bg-green-200 text-colorPrimary focus:outline-none"
          onChange={e => setEmail(e.target.value)}
          placeholder="Email"
          type="text"
          required
        />
      </div>
      <div className="mb-7 font-Poppins">
        <input
          className="w-full rounded-3xl h-[40px] p-4 bg-green-200 text-colorPrimary focus:outline-none text-sm"
          onChange={e => setPhone(e.target.value)}
          placeholder="Phone"
          type="number"
          required
        />
      </div>

      <div className="text-center md:text-end font-Poppins">
        <button
          className={`${
            disable ? "bg-colorTextSecondary" : "bg-yellow"
          } text-sm font-Josefin font-medium px-4 py-2 rounded mt-4`}
          type="submit"
          disabled={disable ? true : false}
        >
          Submit
        </button>
      </div>
    </form>
  );
};

export default ContactForm;
