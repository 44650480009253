"use client";

import axios from "axios";
import { prodServerUrl } from "@/app/utils/globals";
import OurCourseCard from "./OurCourseCard";
import { useEffect, useRef, useState } from "react";

const OurCourseCards = () => {
  const didRunOnce = useRef(false);
  const [courses, setCourses] = useState([]);

  const fetchCourses = async () => {
    try {
      const { data } = await axios.get(`${prodServerUrl}/courses`, {
        headers: {
          "Content-Type": "application/json; charset=UTF-8",
        },
      });

      if (data.success === true) {
        setCourses(data.courses);
      }

    } catch (err) {
      console.log(err.message);
    }
  };

  useEffect(() => {
    if (!didRunOnce.current) {
      fetchCourses();
      didRunOnce.current = true;
    }
  });

  return (
    <div className="md:grid grid-cols-4 place-items-center gap-2 md:gap-28 lg:gap-x-80 lg:gap-y-10">
      {courses.map((course) => {
        return ( course.discountedPrice !== 0 &&
          <OurCourseCard
            title={course.name}
            thumbnail={course.thumbnail.url}
            discountedPrice={course.discountedPrice}
            brochureUrl={course.brochureUrl}
            duration={course.duration}
            key={course._id}
          />
        );
      })}
    </div>
  );
};

export default OurCourseCards;
