const featuresList = [
  {
    id: 1,
    image: "/images/icon1.png",
    title: "Experienced ",
    description: "Trainers",
  },
  {
    id: 2,
    image: "/images/icon2.png",
    title: "Structured",
    description: "Curriculum",
  },
  {
    id: 3,
    image: "/images/icon3.png",
    title: "Live Interactive ",
    description: "Classes",
  },
  {
    id: 4,
    image: "/images/icon4.png",
    title: "Real Life Project ",
    description: "Applications",
  },
  {
    id: 5,
    image: "/images/icon5.png",
    title: "Free Master",
    description: "Classes",
  },
  {
    id: 6,
    image: "/images/icon6.png",
    title: "Placement",
    description: "Support",
  },
  {
    id: 7,
    image: "/images/icon7.png",
    title: "MSME Registered",
    description: "Certificates",
  },
  {
    id: 8,
    image: "/images/icon8.png",
    title: "Lifetime access",
    description: "to community",
  },
];
export default featuresList;
