"use client";
import { motion } from "framer-motion";
import React from "react";
import { ImagesSlider } from "../../ui/ImageSlider";
import TransparentButton from "@/components/layout/Button/TransparentButton";
import Link from "next/link";

export function Slider() {
  const images = [
    "/images/hero1.webp",
    "/images/hero2.webp",
    "/images/hero3.webp",
  ];
  return (
    <ImagesSlider className="h-[40rem]" images={images}>
      <motion.div
        initial={{
          opacity: 0,
          y: -80,
        }}
        animate={{
          opacity: 1,
          y: 0,
        }}
        transition={{
          duration: 0.6,
        }}
        className="z-50 flex flex-col justify-center items-center"
      >
        <motion.p className="font-bold text-xl md:text-6xl text-center bg-clip-text text-transparent bg-gradient-to-b from-neutral-50 to-neutral-400 py-4">
          Your Online Finishing School <br /> Is{" "}
          <span className="text-yellow">Vidyastu</span>
        </motion.p>
        <motion.p className="font-bold text-xs md:text-xl text-center bg-clip-text text-transparent bg-gradient-to-b from-neutral-50 to-neutral-400 py-4">
          Vidyastu empowers careers with high-quality, interactive courses,
          currently having 9500+ Students
        </motion.p>
        <Link href={"/premium-courses"}>
          <button className="px-4 py-2 backdrop-blur-sm border bg-emerald-300/10 border-emerald-300 text-white mx-auto text-center rounded-full relative mt-4">
            <span>Explore Us</span>
            <div className="absolute inset-x-0  h-px -bottom-px bg-gradient-to-r w-3/4 mx-auto from-transparent via-emerald-500 to-transparent" />
          </button>
        </Link>
      </motion.div>
    </ImagesSlider>
  );
}
