export const testimonials = [
  {
    id: 1,
    title: "/images/comp1.png",
  },
  {
    id: 2,
    title: "/images/comp2.png",
  },
  {
    id: 3,
    title: "/images/comp3.png",
  },
  {
    id: 4,
    title: "/images/comp4.png",
  },
  {
    id: 5,
    title: "/images/comp5.png",
  },
  {
    id: 6,
    title: "/images/comp6.png",
  },
  {
    id: 7,
    title: "/images/comp7.png",
  },
  {
    id: 8,
    title: "/images/accenture.png",
  },
  {
    id: 9,
    title: "/images/cdc-logo.png",
  },
  {
    id: 10,
    title: "/images/Brightfield-Info-full.png",
  },
  {
    id: 11,
    title: "/images/sailogo.jpg",
  },
  {
    id: 12,
    title: "/images/uktek_logo.png",
  },
  {
    id: 13,
    title: "/images/Kore.ai_logo-latest.svg",
  },
  {
    id: 14,
    title: "/images/helloindiaclick-logo.png",
  },
  {
    id: 15,
    title: "/images/exl-logo.png",
  },
  {
    id: 16,
    title: "/images/archtech.png",
  }
];
