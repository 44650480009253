import React from 'react';
import Link from 'next/link';
import { FaGooglePlay } from "react-icons/fa";
import Image from 'next/image';



const DownloadOurApp = () => {
  return (
    <div>
      <Link 
        href="https://play.google.com/store/apps/details?id=com.sisyphusinfotech.vidyastu"
      >
        <p className='fixed bottom-14 left-9 z-[100] text-white bg-black p-2 text-sm font-semibold w-10 h-10 md:w-12 md:h-12 rounded-full justify-center flex items-center'><Image src="/images/google-play-icon.svg" width={15} height={15} alt='Google Play Store Icon'/></p>
      </Link>
    </div>
  )
}

export default DownloadOurApp