import ContactForm from "@/components/common/ContactForm";
import LeadFormHome from "@/components/common/LeadFormHome";

const LeadFormSection = () => {
  return (
    <div>
      <div className="bg-cover bg-center bg-bg-leadForm">
        <div className="absolute triangle -mt-4">
          <div className="w-16 overflow-hidden inline-block">
            <div className="h-[480px] bg-yellow rotate-45 transform origin-top-right shadow-md shadow-slate-800"></div>
          </div>
        </div>
        <div className="container px-6 py-8 m-auto md:py-14 md:px-16 lg:px-56">
          <div className="md:flex justify-center items-center md:items-start">
            <div className="text-white md:w-1/2 md:mr-6">
              <div>
                <h2 className="text-4xl font-Poppins text-center md:text-start font-semibold md:mt-8">
                  Get a Callback for a FREE consultation on various courses{" "}
                </h2>
              </div>
              {/* <p className="text-sm mt-10 font-Poppins">
              Launch your tech career! Fill out the form to explore in-demand online courses in coding, data analysis, digital marketing and many more. Get matched with a course advisor for a free personalized plan and unlock your future.
              </p> */}
            </div>
            <div className="md:w-1/2 md:ml-4 my-3 sm:my-0">
              <LeadFormHome />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default LeadFormSection;
