"use client";

import React from "react";
import axios from "axios";
import { prodServerUrl } from "@/app/utils/globals";
import OurFreeCourseCard from "./OurFreeCourseCard";
import { useEffect, useRef, useState } from "react";
import freecourseshome from "../faculty/data/freecourseshome";

const FreeCourses = () => {
  return (
    <div className="container px-6 py-8 m-auto md:py-14 md:px-16 lg:px-56">
      <div className="mb-6 px-10 md:px-0">
        <h2 className="text-2xl font-semibold text-colorHeading">
          FREE COURSES
        </h2>
        <div className="underline my-1"></div>
      </div>
      <div className="grid grid-cols-1 md:grid md:grid-cols-3 place-items-center gap-2 md:gap-28">
      {freecourseshome.map((item) => {
        return (
          <OurFreeCourseCard
            title={item.coursetitle}
            thumbnail={item.image}
            key={item.id}
            resourceUrl={item.playlist}
          />
        );
      })}
      </div>
    </div>
  );
};

export default FreeCourses;
