import React from "react";
import SuccessStories from "./SuccessStories";

const SuccessStorySection = () => {
  return (
    <div className="container mx-auto py-8 m-auto md:py-14 md:px-16 lg:px-56">
      <div className="mb-6 px-14 md:px-0">
        <h3 className="text-2xl font-semibold text-colorHeading uppercase">
          What they're saying about
          <span className="text-red-700"> Vidyastu</span>
        </h3>
        <div className="underline"></div>
        <p className="my-4 text-lg md:text-base text-[#1e86af] font-semibold font-Poppins leading-10">
          Read the reviews by VIDYASTU Alumni on how programs have helped them
          to achieve.
        </p>
      </div>
      <SuccessStories />
    </div>
  );
};

export default SuccessStorySection;
