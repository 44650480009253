import { FaLinkedin, FaGithub } from "react-icons/fa";

const faculties = [
  {
    id: 12,
    image: "/images/admin1.jpeg",
    title: "Nirmalya Sengupta",
    description: "Python Faculty",
    linkedinIcon: "/images/linkedin.webp",
    linkedinUrl: "https://www.linkedin.com/in/nirmalya-sengupta-901597215/",
  },
  {
    id: 13,
    image: "/images/admin2.jpg",
    title: "Avik Sarkar",
    description: "Corporate Groomer",
    linkedinIcon: "/images/linkedin.webp",
    linkedinUrl: "https://www.linkedin.com/in/avik79sarkar/",
  },
  {
    id: 14,
    image: "/images/faculty4.png",
    title: "Sayan Barik",
    description: "A data analyst with expertise in BI Development, SQL, ETL Tools & Cloud architecture. Managing migration project from different tools to Tableau/Power BI as technical lead in a reputed MNC.",
    linkedinIcon: "/images/linkedin.webp",
    linkedinUrl: "https://www.linkedin.com/in/sayan-barick-65247b137/"
  },
  {
    id: 2,
    image: "/images/faculty2.png",
    title: "Tushar De",
    description: "A Web Application Penetration Tester, OSINT enthusiast, and full-stack MERN developer. As CEO of Sisyphus Infotech, he leads in IT innovation and cybersecurity excellence.",
    githubIcon: "/images/github.webp",
    linkedinIcon: "/images/linkedin.webp",
    linkedinUrl: "https://www.linkedin.com/in/0xtd",

  },
  {
    id: 6,
    image: "/images/sharmistha.jpeg",
    title: "Sharmistha Chatterjee",
    description: "Full Stack Web Developer and UI/UX Designer",
    githubIcon: "/images/github.webp",
    linkedinIcon: "/images/linkedin.webp",
    linkedinUrl: "https://www.linkedin.com/in/sharmistha-chatterjee-5a5867238/",
  },
  {
    id: 3,
    image: "/images/faculty3.png",
    title: "Ushashi Chowdhury",
    description: "Data Analyst",
    githubIcon: "/images/github.webp",
    linkedinIcon: "/images/linkedin.webp",
    linkedinUrl: "https://www.linkedin.com/in/ushashi-chowdhury-53bb59258/",
  },
  // {
  //   id: 5,
  //   image: "/images/faculty5.png",
  //   title: "Kaustav Basak",
  //   description: "Digital Marketer",
  // },
  // {
  //   id: 7,
  //   image: "/images/priyam.jpeg",
  //   title: "Priyam Sengupta",
  //   description: "News Content Writer",
  //   linkedinIcon: "/images/linkedin.webp",
  //   linkedinUrl: "",
  // },
  // {
  //   id: 8,
  //   image: "/images/faculty6.png",
  //   title: "Sayani Dutta",
  //   description: "Content Writer",
  //   linkedinIcon: "/images/linkedin.webp",
  //   linkedinUrl: "",
  // },
  // {
  //   id: 9,
  //   image: "/images/faculty7.jpeg",
  //   title: "Arinita Paul",
  //   description: "Web Developer",
  // },
  {
    id: 10,
    image: "/images/faculty8.png",
    title: "Nilanjan Bhadra",
    description: "A performance marketer with 4 years of experience in Google Ads, Meta Ads, and SEO. Currently helping businesses establish an online presence and grow their business online.",
    githubIcon: "/images/github.webp",
    linkedinIcon: "/images/linkedin.webp",
    linkedinUrl: "https://www.linkedin.com/in/nilanjanbhadra/"
  },
  {
    id: 11,
    image: "/images/victor.png",
    title: "Victor Bhattacharya",
    description: "Digital Marketer",
    githubIcon: "/images/github.webp",
    linkedinIcon: "/images/linkedin.webp",
    linkedinUrl: "https://www.linkedin.com/in/victor-bhattacharya-682536b8/",
  },
  {
    id: 15,
    image: "/images/anumita.jpeg",
    title: "Anumita Bhattacharya",
    description: "Anumita holds a Master's in Computer Applications with 6 years of teaching experience. As a software engineer at Sett Consultant, she has contributed to numerous projects.",
    githubIcon: "/images/github.webp",
    linkedinIcon: "/images/linkedin.webp",
    linkedinUrl: "https://www.linkedin.com/in/anumita-bhattacharya-99532018b/?utm_source=share&utm_campaign=share_v",
  },
  {
    id: 16,
    image: "/images/arijit.jpg",
    title: "Arijit Banerjee",
    description: "A Full Stack Web Developer, with a strong foundation in the MERN stack. Driven by a love for problem-solving, I'm actively learning and solving data structure problems.",
    githubIcon: "/images/github.webp",
    linkedinIcon: "/images/linkedin.webp",
    linkedinUrl: "https://www.linkedin.com/in/arijit-banerjee-73204420a/",
  },
];
export default faculties;
