import React from "react";
import Image from "next/image";
import CallToActionButton from "@/components/layout/Button/CallToActionButton";

const CallToActionSection = () => {
  return (
    <div className="container px-6 py-8 m-auto md:py-14 md:px-16 lg:px-56 md:flex md:justify-center">
      <div className="p-12 bg-[#BCF2E1] rounded-t md:rounded-t-none md:rounded-l shadow-md shadow-slate-600 w-full">
        <div className="flex">
          <div className="flex justify-center items-center w-[100px] h-[100px] bg-yellow rounded-full ">
            <Image
              className="block w-[60px]"
              src="/images/msme.png"
              width={60}
              height={60}
              alt="msme logo"
            />
          </div>
          <Image
            className="block -ml-12"
            src="/images/badge.png"
            width="100"
            height="100"
            alt="msme"
          />
        </div>

        <h4 className="text-base font-medium text-gray-900">
          BOOST YOUR SKILL
        </h4>
        <div className="underline"></div>
        <h3 className="my-1 text-sm md:text-base text-gray-800">
          Get your MSME Registered Online Certificate from{" "}
          <span className="text-[#03524b] font-semibold text-sm">VIDYASTU</span>
        </h3>

        <div className="mt-4">
          <CallToActionButton btnText="Know More" link="/about" />
        </div>
      </div>

      <Image
        className="md:rounded-tr-md rounded-bl-md md:rounded-bl-none rounded-br-md shadow-md shadow-slate-600 block w-full md:w-[260px]"
        src="/images/certificate.png"
        width={320}
        height={280}
        alt="about"
      />
    </div>
  );
};

export default CallToActionSection;
