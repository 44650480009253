import FeatureCard from "../../common/FeatureCard";
import featuresList from "./data/features-list";

const FeaturesList = () => {
  return (
    <div className="grid grid-cols-2 md:grid-cols-4 gap-3 md:gap-4">
      {featuresList.map((feature) => {
        return (
          <FeatureCard
            key={feature.id}
            icon={feature.image}
            title={feature.title}
            description={feature.description}
          />
        );
      })}
    </div>
  );
};

export default FeaturesList;
