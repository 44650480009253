import React from "react";

const Companies = () => {
  return (
    <div className="logos overflow-hidden p-14 bg-white">
      <div className="logos-slide flex whitespace-nowrap	">
        <img className=" h-[50px] m-6" src="/images/comp1.png" alt=""/>
        <img className=" h-[50px] m-6" src="/images/comp2.png" alt=""/>
        <img className=" h-[50px] m-6" src="/images/comp3.png" alt=""/>
        <img className=" h-[50px] m-6" src="/images/comp4.png" alt=""/>
        <img className=" h-[50px] m-6" src="/images/comp5.png" alt=""/>
        <img className=" h-[50px] m-6" src="/images/comp6.png" alt=""/>
        <img className=" h-[50px] m-6" src="/images/comp7.png" alt=""/>
        <img className=" h-[50px] m-6" src="/images/accenture-logo.png" alt="" />
        <img className=" h-[50px] m-6" src="/images/cdc-logo.png" alt="" />
        <img className=" h-[50px] m-6" src="/images/Brightfield-Info-full.png" alt=""  />
        <img className=" h-[50px] m-6" src="/images/sailogo.jpg" alt=""  />
        <img className=" h-[50px] m-6" src="/images/uktek_logo.png" alt="" />
        <img className=" h-[50px] m-6" src="/images/Kore.ai_logo-latest.svg" alt=""  />
        <img className=" h-[50px] m-6" src="/images/helloindiaclick-logo.png" alt="" />
        <img className=" h-[50px] m-6" src="/images/exl-logo.png" alt="" />
        
      </div>
    </div>
  );
};

export default Companies;
