import React from "react";
import Image from "next/image";
import CallToActionButton from "@/components/layout/Button/CallToActionButton";

const AboutUsSection = () => {
  return (
    <div className="container px-6 py-8 m-auto md:py-14 md:px-16 lg:px-56">
      <div className="lg:flex justify-between items-center">
        {/* left side */}
        <div className="flex flex-col justify-center items-center lg:w-1/2">
          <div className="mr-4 mt-6 md:mt-1">
            <Image
              className="md:relative rounded-tl-[50px] rounded-br-[50px] shadow-md shadow-slate-500 block"
              src="/images/about-left.jpg"
              width={350}
              height={300}
              alt="about"
            />
          </div>
          <div className="flex items-end justify-center -mt-12">
            <div className="bg-colorAqua rounded-l-full px-4 py-2 h-12 flex justify-center items-center">
              <p className="text-yellow font-medium text-4xl mx-2">5</p>
              <span className="text-white text-sm ml-2">
                Years of Excellence
              </span>
            </div>
            <div className="">
              <Image
                className="rounded-tl-[50px] rounded-br-[50px] shadow-md shadow-slate-600 block"
                src="/images/about-right.jpg"
                width={300}
                height={300}
                alt="about"
              />
            </div>
          </div>
        </div>
        {/* Right side */}
        <div className="mt-10 ml-10 lg:mt-0 lg:w-1/2 font-Poppins ">
          <h4 className="text-2xl text-gray-900 leading-loose font-semibold">
            GET TO KNOW MORE
          </h4>
          <div className="underline"></div>
          <h3 className="my-4 text-md md:text-base text-[#1e86af] font-semibold leading-10">
            Grow your skills and learn with us from everywhere!
          </h3>
          <p className="mt-1 text-sm text-gray-600 leading-6">
            We empower learners with in-demand technical skills through
            flexible, online courses. Master coding, data analysis, or IT
            fundamentals at your own pace. Expert instructors and real-world
            projects prepare you for success in today's job market.
          </p>
          <div className="text-end mt-4">
            <CallToActionButton btnText="Know More" link="/about" />
          </div>
        </div>
      </div>
    </div>
  );
};
export default AboutUsSection;
