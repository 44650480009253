import Image from "next/image";

const ReviewCard = ({ imgSrc, name, review }) => {
  return (
    <div className="border border-zinc-400 rounded-3xl p-4 bg-white h-[320px] w-full mx-4">
      <div className="flex">
        <Image
          src={imgSrc}
          width={50}
          height={50}
          alt={name}
          className="block w-[50px] h-[50px] rounded-full"
        />
        <h6 className="text-[#1e86af] font-semibold font-Poppins text-sm ml-2 text-start">
          {name}
        </h6>
      </div>
      <p className="font-Poppins text-stone-600 text-[10px] leading-4 mt-2 overflow-y-scroll px-6">
        {review}
      </p>
    </div>
  );
};

export default ReviewCard;
