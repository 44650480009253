import Link from "next/link";
import { FaStar } from "react-icons/fa";
import { RxDownload } from "react-icons/rx";

const OurFreeCourseCard = ({
  thumbnail,
  discountedPrice,
  title,
  resourceUrl
}) => {
  return (
    <div className="w-[300px] md:w-[210px] md:h-[380px] lg:h-full md:-ml-6 lg:-ml-0 lg:w-[280px] bg-white shadow-lg shadow-gray duration-500  hover:shadow-2xl hover:shadow-black rounded border-gray-300 rounded-br-[25px] font-Poppins mb-4">
      {/* IMAGE */}
      <div className="w-full">
        <img
          src={thumbnail}
          alt={title}
          width={1380}
          height={820}
          className="rounded-tl rounded-tr block h-[150px]"
        />
      </div>
      {/* BUTTON  */}
      <div className="-mt-5">
        <button className="bg-yellow ml-4 px-3 py-2 rounded-full uppercase font-medium cursor-default text-sm">
          {discountedPrice > 0 ? "Premium" : "Free"}
        </button>
      </div>
      {/* COURSE NAME */}
      <div className="w-full h-16 px-4 py-2">
        <p className="text-sm font-medium">{title}</p>
      </div>

      <div className="border-b-[1px] border-gray-300"></div>

      {/* RATING */}
      <div className="m-4 flex">
        <FaStar className=" text-xl text-orange-400" />
        <FaStar className=" text-xl text-orange-400" />
        <FaStar className=" text-xl text-orange-400" />
        <FaStar className=" text-xl text-orange-400" />
        <FaStar className=" text-xl text-orange-400" />
      </div>
      <Link
          href={`${resourceUrl}`}
          target="_blank"
        >
          <button className="p-2 text-xs border mx-3 border-black">
            Start Learning
          </button>
        </Link>
    </div>
  );
};

export default OurFreeCourseCard;
