import React from "react";

const Founder = () => {
  return (
    <div className="container mx-auto px-4 py-2 bg-white my-20 flex flex-col justify-center items-center lg:flex lg:flex-row">
      {/* DESCRIPTION  */}
      <div className="bg-gray-100 lg:w-1/2 h-[700px] lg:h-[600px] rounded-[50px] flex justify-center items-center px-4 py-5 md:p-16 mx-15">
        <div className="text-center md:text-left w-[480px]">
          <h2 className="text-3xl font-Poppins">Nirmalya Sengupta</h2>
          <p className="text-xl py-6 text-[#1e86af] font-Poppins">
            Founder & Managing Director
          </p>
          <p className="text-sm leading-[1.75rem] text-gray-500 py-3 px-14 md:px-0 lg:px-0 font-Poppins">
            Lorem ipsum dolor sit amet consectetur adipisicing elit. Repudiandae
            eum reprehenderit accusantium voluptate culpa molestias, minus
            ratione illum vel eos quidem! Odio maiores illo unde quasi
          </p>
        </div>
      </div>
      {/* IMAGE  */}
      <div className="flex items-center">
        <img
          src="/images/admin1.jpeg"
          alt="admin"
          className="w-[250px] md:w-full h-full md:h-[400px] lg:h-[380px] rounded-[50px] md:mb-10 lg:mb-0"
        />
      </div>
    </div>
  );
};

export default Founder;
