import React from "react";
import { CiStreamOn } from "react-icons/ci";
import { TiTick } from "react-icons/ti";
import Link from "next/link";

const Internship = () => {
  return (
    <div className="my-4 md:flex-col">
      {/* BANNER  */}
      <div className="w-full h-[500px] md:h-[250px] bg-cover bg-center bg-bg-intern">
        <div className="absolute triangle -mt-4">
          <div className="w-16 overflow-hidden inline-block">
            <div className="h-[480px] bg-yellow rotate-45 transform origin-top-right shadow-md shadow-slate-800"></div>
          </div>
        </div>

        <div className="md:flex items-start justify-center pt-4 md:gap-4 lg:gap-32">
          {/* CALENDAR  */}
          <div className="mt-8 px-28 md:px-0 text-center md:text-left z-50">
            <Link href="https://ebluesoft.in/" target="_blank">
              <span className="bg-yellow px-3 py-4 rounded-2xl text-black text-center text-sm font-Poppins">
                EBlueSoft
              </span>
            </Link>

            <div className="border-[#79bbb4] border-4 rounded-full shadow-md mt-6">
              <img
                src="./images/eblue.png"
                alt=""
                className="w-[100px] md:w-[200px] h-[100px] md:h-[200px] rounded-full"
              />
            </div>
            <p className="text-yellow font-semibold">Enroll for best </p>
            <p className="text-white">Digital Marketing Internship</p>
            <li className="flex">
              <TiTick className="text-yellow text-2xl" />

              <span className="text-[16px] text-white">Live Projects</span>
            </li>
            <li className="flex">
              <TiTick className="text-yellow text-2xl" />
              <span className="text-[16px] text-white">Certificates</span>
            </li>
          </div>
          {/* WEBINAR INFO */}
          <div className="text-center mt-6 font-Poppins">
            <p className="">
              THERE'S AN{" "}
              <span className="text-[#077b70] font-semibold">
                UNLIMITED AMOUNT <span className="text-sm text-gray"> OF </span>
              </span>
            </p>
            <p className="text-[#077b70] text-4xl font-Poppins font-semibold">
              OPPORTUNITY
            </p>
            {/* <p className='text-[#077b70] text-3xl font-Poppins font-semibold'></p> */}
            <p className="text-[#B91C1C] uppercase md:w-[400px] lg:w-[500px] font-semibold text-[24px]">
              IF YOU'RE READY!
            </p>
          </div>
          {/* STICKER  */}

          <div className="mt-8 px-28 md:px-0 text-center md:text-left z-50">
            <Link href="https://sisyphusinfotech.com/" target="_blank">
              <span className="bg-yellow px-3 py-4 rounded-2xl text-black text-center text-sm font-Poppins">
                Sisyphus Infotech
              </span>
            </Link>

            <div className="border-[#79bbb4] border-4 rounded-full shadow-md mt-5">
              <img
                src="./images/sisyphus.png"
                alt=""
                className="w-[100px] md:w-[200px] h-[100px] md:h-[200px] rounded-full"
              />
            </div>

            <p className="text-yellow font-semibold">Enroll for Internships </p>
            <p className="text-white">Digital Marketing</p>
            <p className="text-white">and Web Development</p>

            <li className="flex">
              <TiTick className="text-yellow text-2xl" />

              <span className="text-[16px] text-white">Live Projects</span>
            </li>
            <li className="flex">
              <TiTick className="text-yellow text-2xl" />

              <span className="text-[16px] text-white">Certificates</span>
            </li>
          </div>
        </div>
      </div>
      {/* SPEAKER  */}
      <div className="bg-bg-internship bg-cover h-[230px] -mt-16 z-10">
        <div className="w-full h-[450px] md:h-[230px] bg-green-300/30 backdrop-brightness-50">
          <div className="text-center mt-6">
            <p className="text-white text-sm font-Poppins font-semibold mt-4">
              Our non-paid
            </p>
            <p className="text-yellow text-5xl font-Poppins font-bold my-1">
              INTERNSHIP
            </p>
            <p className="text-white my-3 text-sm font-Poppins font-semibold">
              offers hands on practice on live projects to boost your skill.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Internship;
