import React from 'react';
import Link from 'next/link';
import { FaGooglePlay } from "react-icons/fa";
import Image from 'next/image';



const RequestCallback = () => {
  return (
    <div className='fixed bottom-0 z-[100] text-black bg-green-500 p-2 text-sm font-semibold w-full h-12 justify-center flex items-center'>
      <p className='font-bold text-[15px] md:text-[18px]'>Need Help? <span>Talk to Us at +91-9051977167 </span></p>
    </div>
  )
}

export default RequestCallback