import Image from "next/image";
import React, { useState } from "react";
import { IoCloseCircleOutline } from "react-icons/io5";
import PhoneInputWithCountrySelect from "react-phone-number-input";
// import "react-phone-number-input/style.css";

const Popup = ({ onClose }) => {
  const [name, setName] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [showPopup, setShowPopup] = useState(false);

  const handleSubmit = (event) => {
    event.preventDefault();
    // Here you would typically send the data to a server or perform other actions
    console.log("Name:", name);
    console.log("Phone Number:", phoneNumber);
    onClose();
  };

  return (
    <div className="popup-for-syllabus">
      <div className="bg-white rounded shadow-lg px-11 py-8 w-[41rem]">
        <div className="flex flex-row justify-between items-center">
          <div className="bg-zinc-100 py-14 px-3">
            <Image
              src="/assets/logo/logo_transparent.png"
              width={200}
              height={200}
              alt="logo"
              className="rounded-full"
            />
            <p className="text-xs my-5 text-zinc-600 font-bold">
              Your Online Finishing School
            </p>
          </div>
          <div className="w-96 pl-8">
            <h2 className="text-xl font-bold mb-4 text-zinc-600">
              Enter your details to download the program syllabus
            </h2>
            <form onSubmit={handleSubmit}>
              <div className="mb-4">
                <label
                  htmlFor="name"
                  className="block text-gray-700 font-bold mb-2"
                >
                  Name
                </label>
                <input
                  type="text"
                  id="name"
                  value={name}
                  onChange={(e) => setName(e.target.value)}
                  className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                />
              </div>
              <div className="mb-6">
                <label
                  htmlFor="phoneNumber"
                  className="block text-gray-700 font-bold mb-2"
                >
                  Phone Number
                </label>
                <PhoneInputWithCountrySelect
                  placeholder="Enter phone number"
                  value={phoneNumber}
                  onChange={setPhoneNumber}
                  international
                  defaultCountry="IN"
                  className="appearance-none rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                  inputStyle={{
                    width: "100%",
                    padding: "30px",
                    borderRadius: "4px",
                    fontSize: "16px",
                    fontFamily: "Arial, sans-serif",
                    backgroundColor: "white",
                  }}
                  buttonStyle={{
                    backgroundColor: "#f0f0f0",
                    borderRadius: "4px",
                    fontSize: "16px",
                    fontFamily: "Arial, sans-serif",
                    padding: "8px 12px",
                    cursor: "pointer",
                  }}
                  countrySelectStyle={{
                    backgroundColor: "#f0f0f0",
                    borderRadius: "4px",
                    fontSize: "14px",
                    fontFamily: "Arial, sans-serif",
                    padding: "8px 12px",
                    cursor: "pointer",
                  }}
                />
              </div>
              <div className="flex items-center justify-between">
                <button
                  type="button"
                  onClick={onClose}
                  className="border border-black text-black font-bold py-2 px-4 rounded mr-4"
                >
                  Cancel
                </button>
                <button
                  type="submit"
                  className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded"
                >
                  Submit
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Popup;
