import React from "react";
import { TiTick } from "react-icons/ti";
import TransparentButton from "@/components/layout/Button/TransparentButton";
import Image from "next/image";
import Link from "next/link";
import { FaWhatsapp } from "react-icons/fa";
import { SiGooglemeet } from "react-icons/si";


const EventCard = ({
  title,
  subtitle,
  date,
  dayOfWeek,
  time,
  speaker,
  speakerAvatarUrl,
  eventJoinLink,
  topics,
}) => {
  return (
    <div className="bg-gradient-to-r from-black from-10% to-emerald-900 to-90% mb-9 lg:h-[620px] rounded-3xl">
      <div className="text-center mb-4">
        <p className="text-yellow uppercase font-bold text-lg md:text-3xl my-4 pt-2">
          {title}
        </p>
        <h6 className="text-white text-xs">{subtitle}</h6>
      </div>

      <div className="font-Poppins">
        {/* heading */}
        <div className="">
          <div className="border-2 border-white rounded-tl-lg rounded-br-lg p-2 mx-1 md:p-4 text-center text-lg md:mx-24">
            <p className="text-white border-r-2 border-white px-2 md:px-4 text-sm inline-block">
              {time}
            </p>
            <p className="text-white border-r-2 border-white px-2 inline-block md:px-4 text-sm">
              {dayOfWeek}
            </p>
            <p className="text-white px-2 md:px-4 text-sm inline-block">
              {date}
            </p>
          </div>
          {/* content */}
          <div className="flex flex-col md:flex-row md:justify-center items-center">
            <div className="leading-10 md:w-[600px]">
              <ul className="my-4 md:my-0">
                <li className="flex text-start items-center  bg-green-900 md:bg-transparent rounded-lg md:rounded-none mb-3 mr-2 p-1">
                  <TiTick className="text-yellow text-2xl" />

                  <span className="text-sm md:text-[16px] md:leading-5 text-white">
                    {topics[0]}
                  </span>
                </li>
                <li className="flex text-start items-center  bg-green-900 md:bg-transparent rounded-lg md:rounded-none mb-3 mr-2 p-1">
                  <TiTick className="text-yellow text-2xl" />

                  <span className="text-sm md:text-[16px] md:leading-5 text-white">
                    {topics[1]}
                  </span>
                </li>
                <li className="flex text-start items-center  bg-green-900 md:bg-transparent rounded-lg md:rounded-none mb-3 mr-2 p-1">
                  <TiTick className="text-yellow text-2xl" />
                  <span className="text-sm md:text-[16px] md:leading-5 text-white">
                    {topics[2]}
                  </span>
                </li>
                {/* <li className="flex items-center">
                  <TiTick className="text-yellow text-2xl" />
                  <span className="text-[16px] text-white">
                    {topics[3]}
                  </span>
                </li> */}
              </ul>
              {/* <TransparentButton href="/event" /> */}
              <div className="flex flex-row items-center justify-center md:justify-start gap-8 md:gap-3">
                <Link
                  href={"https://chat.whatsapp.com/ClWl4daXrSpFi0cHftk0pl"}
                  target="_blank"
                >
                  <button className="text-white text-[14px] p-3 rounded-lg mt-10 flex justify-center items-center gap-2 w-20">
                    <FaWhatsapp className="text-green-400 text-4xl" /></button>
                </Link>
                <Link
                  href={`${eventJoinLink}`}
                  target="_blank"
                >
                  <button className="text-white text-[14px] p-3 rounded-lg mt-10 flex justify-center items-center gap-2 w-20">
                    <Image className="animate-pulse hover:animate-none" width={40} height={40} alt="google meet icon" src={'/images/google-meet-svgrepo-com.svg'}/></button>
                </Link>
              </div>
            </div>
            {/* image */}
            <div className="flex flex-col items-center justify-center">
              {/* <div className="absolute w-[220px] h-[220px] border-2 border-white rounded-tl-3xl rounded-br-3xl mt-[5rem] xm:ml-0 md:ml-[6rem]"></div> */}
              <Image
                className="block shadow-md w-[230px] h-[280px] ml-6 lg:ml-0 mt-12 rounded"
                src={speakerAvatarUrl}
                width={800}
                height={800}
                alt="about"
              />
              <p className=" text-zinc-300 text-sm py-8 md:py-2 px-4 lg:px-[-10px] w-80 md:w-96 text-center">
                {speaker}
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
    // <div className="bg-gradient-to-r from-black from-10% to-emerald-900 to-90% mb-9 lg:h-[620px] rounded-3xl">
    //   <div className="text-center mb-4">
    //     <p className="text-yellow uppercase font-bold text-lg md:text-3xl my-4 pt-2">
    //     FREE WEBINAR ON MUTUAL FUND, TRADING AND STOCK MARKET.
    //     </p>
    //     <h6 className="text-white text-xs md:text-[15px]">Discover the basics of investing with Mutual Funds, the Stock Market, and Trading. Learn how mutual funds pool money to invest in diversified portfolios, how stocks represent ownership in companies, and how trading allows you to buy and sell these assets. Get started on your journey to financial growth and market insights!</h6>
    //   </div>

    //   <div className="font-Poppins">
    //     {/* heading */}
    //     <div className="">
    //       <div className="border-2 border-white rounded-tl-lg rounded-br-lg p-2 mx-1 md:p-4 text-center text-lg md:mx-24">
    //         <p className="text-white border-r-2 border-white px-2 md:px-4 text-sm inline-block">
    //           8:30 PM
    //         </p>
    //         <p className="text-white border-r-2 border-white px-2 inline-block md:px-4 text-sm">
    //           Wednesday
    //         </p>
    //         <p className="text-white px-2 md:px-4 text-sm inline-block">
    //           August 14
    //         </p>
    //       </div>
    //       {/* content */}
    //       <div className="flex flex-col md:flex-row md:justify-around items-end">
    //         <div className="leading-10 md:w-[600px] justify-start">
    //           <ul className="my-4 md:my-0">
    //             <li className="flex text-start items-center  bg-green-900 md:bg-transparent rounded-lg md:rounded-none mb-3 mr-2 p-1">
    //               <TiTick className="text-yellow text-2xl" />

    //               <span className="text-sm md:text-[16px] md:leading-5 text-white">
    //                 Introduction to Mutual Funds
    //               </span>
    //             </li>
    //             <li className="flex text-start items-center  bg-green-900 md:bg-transparent rounded-lg md:rounded-none mb-3 mr-2 p-1">
    //               <TiTick className="text-yellow text-2xl" />

    //               <span className="text-sm md:text-[16px] md:leading-5 text-white">
    //                 Trading Strategies for Beginners
    //               </span>
    //             </li>
    //             <li className="flex text-start items-center  bg-green-900 md:bg-transparent rounded-lg md:rounded-none mb-3 mr-2 p-1">
    //               <TiTick className="text-yellow text-2xl" />
    //               <span className="text-sm md:text-[16px] md:leading-5 text-white">
    //                 Analyzing the Stock Market
    //               </span>
    //             </li>
    //             {/* <li className="flex items-center">
    //               <TiTick className="text-yellow text-2xl" />
    //               <span className="text-[16px] text-white">
    //                 {topics[3]}
    //               </span>
    //             </li> */}
    //           </ul>
    //           {/* <TransparentButton href="/event" /> */}
    //           <Link
    //             href={"https://chat.whatsapp.com/ClWl4daXrSpFi0cHftk0pl"}
    //             target="_blank"
    //           >
    //             <button className="text-white border border-green-400 text-[14px] p-3 rounded-lg mt-10 flex justify-center items-center gap-2">
    //               <FaWhatsapp className="text-green-400 text-xl" /> Join Our
    //               Community
    //             </button>
    //           </Link>
    //         </div>
    //         {/* image */}
    //         <div className="flex flex-col justify-center items-center md:flex-row md:justify-end md:items-end">
    //           <div className="flex flex-col items-center justify-start h-96">
    //             <Image
    //               className="block shadow-md w-[150px] h-[220px] md:w-[230px] md:h-[300px] ml-6 lg:ml-0 mt-12 rounded-lg md:mb-3"
    //               src='https://res.cloudinary.com/dgj931yed/image/upload/v1723553243/ejlzfukwhtbx1tlj0z3w.jpg'
    //               width={500}
    //               height={500}
    //               alt="about"
    //             />
    //             <p className=" text-zinc-300 text-sm py-8 md:py-2 px-4 lg:px-[-10px] w-80 md:w-96 text-center">
    //               PRITAM CHATTERJEE. (DEPUTY VICE PRESIDENT, SBI)
    //             </p>
    //           </div>
    //           <div className="flex flex-col items-center justify-start h-96">
    //             <Image
    //               className="block shadow-md w-[150px] h-[220px] md:w-[230px] md:h-[300px] ml-6 lg:ml-0 mt-12 rounded-lg md:mb-3"
    //               src='https://res.cloudinary.com/dgj931yed/image/upload/v1723553243/rksuecyozkvara26kwxh.jpg'
    //               width={500}
    //               height={500}
    //               alt="about"
    //             />
    //             <p className=" text-zinc-300 text-sm py-8 md:py-2 px-4 lg:px-[-10px] w-80 md:w-96 text-center">
    //               RAKESH BANERJEE (CHIEF MAGAGER, SBI)
    //             </p>
    //           </div>
    //         </div>
    //       </div>
    //     </div>
    //   </div>
    // </div>
  );
};

export default EventCard;
