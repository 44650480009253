import React from "react";
import "./textcolor.css";

const NewsChannel = () => {
  return (
    <div className="container px-6 py-8 m-auto md:py-14 md:px-16 lg:px-56 md:flex flex-col md:justify-center">
      <div className="flex flex-col justify-center items-center">
        <h1 className="text-4xl font-bold">
          Vidyastu <span className="textcolor">in the News</span>
        </h1>
        <p className="text-zinc-600">We make the headlines</p>
      </div>
      {/* News Section */}
      <div className="flex flex-row justify-between items-center">
        <div className="flex flex-col items-center justify-center border border-red-500 w-96 h-40">
          <h2 className="bg-[#fcf1f5] w-96 h-28 flex justify-center items-center">Hindusthan Samachar</h2>
          <p>News Short Description</p>
          <p>Publish Date</p>
        </div>
        <div className="flex flex-col items-center justify-center border border-red-500 w-96 h-40">
          <h2 className="bg-[#fcf1f5] w-96 h-28 flex justify-center items-center">Hindusthan Samachar</h2>
          <p>News Short Description</p>
          <p>Publish Date</p>
        </div>
      </div>
    </div>
  );
};

export default NewsChannel;
