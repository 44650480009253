import Image from "next/image";

const FacultyCard = ({ avatar, facultyName, description }) => {
  return (
    <div className="border border-yellow flex flex-col md:flex-row justify-start px-2 py-4 rounded m-1 w-[47%]">
      <Image
        src={avatar}
        width={50}
        height={50}
        alt={facultyName}
        className="w-[50px] h-[50px] md:h-[82px] md:w-[80px] border-2 border-white shadow shadow-slate-600 rounded-full mr-2 block"
      />
      <div className="my-4">
        <p className="font-Poppins font-semibold text-sm text-colorHeading ">{facultyName}</p>
        <p className="text-gray-600 text-xs font-Poppins">{description}</p>
      </div>
    </div>
  );
};

export default FacultyCard;
