import Image from "next/image";

const FeatureCard = ({ icon, title, description }) => {
  return (
    <div className="shadow shadow-green-300 bg-white p-6 rounded-tl-2xl rounded-br-2xl">
      {/* image */}
      <div className="flex justify-center">
        <Image src={icon} alt={title} height={35} width={35} className="w-6 md:w-10" />
      </div>
      {/* content */}
      <div className="text-center text-[#1e86af] font-Poppins text-xs md:text-sm font-regular mt-3">
        <p>
          {title}
          <span className="block">{description}</span>
        </p>
      </div>
    </div>
  );
};
export default FeatureCard;
