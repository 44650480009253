import Link from "next/link";

const TransparentButton = (href) => {
  return (
    <Link
      href="/about"
      className="px-4 py-2 font-medium text-sm border border-[#FFD966] text-white rounded shadow hover:bg-colorAqua hover:border-colorAqua"
    >
      EXPLORE US
    </Link>
  );
};

export default TransparentButton;
