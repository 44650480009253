import faculties from "./data/faculties";
import FacultyCard from "./FacultyCard";

const AllFaculties = () => {
  return (
    <div className="flex flex-wrap">
      {faculties.map((faculty) => {
        return (
          <FacultyCard
            key={faculty.id}
            avatar={faculty.image}
            facultyName={faculty.title}
            description={faculty.description}
          />
        );
      })}
    </div>
  );
};

export default AllFaculties;
